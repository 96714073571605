import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Grid from "../../components/grid"
import Constrain from "../../components/constrain"
import Heading from "../../components/heading"
import Image from "../../components/image"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const Mediaguide = () => {
  const data = useStaticQuery(graphql`
    query MediaguideBlSehQuery {
      teaser1: file(relativePath: { eq: "mediaguide/audio.jpg" }) {
        ...largeImage
      }
      teaser2: file(relativePath: { eq: "mediaguide/audio-2.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Angebote für blinde und sehbeeinträchtigte Menschen"
        description="Mit dem Media-Guide erfahren Sie spannende Hintergründe zu den Objekten und Themen der Dauerausstellung. Der Media-Guide ist inklusiv: Er enthält Inhalte in Leichter Sprache und Gebärdensprache, Angebote für blinde und sehbeeinträchtigte Menschen und in den Sprachen Deutsch, Englisch und Französisch."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Media-Guide",
                link: "/mediaguide",
              },
              {
                title: "Angebote für blinde und sehbeeinträchtigte Menschen",
                link: "/mediaguide/blinde-sehbeeintraechtigte",
              },
            ]}
          />
          <PageTitle>
            Angebote für blinde und sehbeeinträchtigte Menschen
          </PageTitle>
        </Stack>
        <Constrain>
          <Stack space={6}>
            <Heading as="h2" level={3}>
              Tastleitplan zum Ausleihen
            </Heading>
            <AudioPlayer src="mediaguide/tastleitplan" />
            <Paragraph>
              An der Museumskasse gibt es einen Tastleitplan. Mit dem Plan
              können alle Räume des Museums taktil erfasst werden. Auch der
              Museumsgarten. Wichtige Räume, Informationen und Angebote für
              blinde und sehbeeinträchtigte Menschen sind mit Braille
              beschriftet. Den Plan kann man sich ausleihen und damit durch das
              Museum gehen.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain>
          <Stack space={6}>
            <Heading as="h2" level={3}>
              Hör-Führung mit Beschreibungen und Navigationshilfen
            </Heading>
            <AudioPlayer src="mediaguide/inhalte-mediaguide" />
            <Paragraph>
              Über den Media-Guide zur Dauerausstellung können Sie eine
              Hör-Führung abrufen. Damit erhalten Sie Beschreibungen und
              Navigationshilfen zu den Ausstellungsräumen. Und Sie erhalten
              Audiodeskriptionen zu ausgewählten Ausstellungsstücken.
            </Paragraph>
          </Stack>
        </Constrain>
        <Grid columns={[1, 2]} space={"px"}>
          <Image
            image={data.teaser1.childImageSharp.gatsbyImageData}
            alt="Eine Besucherin beim Verwenden des Media-Guides vor einer Vitrine"
          />
          <Image
            image={data.teaser2.childImageSharp.gatsbyImageData}
            alt="Eine Besucherin hört sich die Audioinhalte zu Hölderlins Schreibtisch an"
          />
        </Grid>
        <Constrain>
          <Stack space={6}>
            <Heading as="h2" level={3}>
              Gerät mit taktilen Tasten
            </Heading>
            <AudioPlayer src="mediaguide/benutzung" />
            <Paragraph>
              Wenn Sie den Media-Guide benutzen möchten, können Sie sich an der
              Museumskasse ein eigenes Gerät dafür ausleihen. Es hat taktile
              Tasten und ist speziell für blinde und sehbeeinträchtigte Menschen
              gemacht. Sie können sich den Media-Guide aber auch als App auf Ihr
              eigenes Handy herunterladen und ihn mit einer Lesehilfe-Funktion
              benutzen. Dafür brauchen Sie Kopfhörer.
            </Paragraph>
            <Paragraph>
              In der Media-Guide-App leitet Sie die Hör-Führung Raum für Raum
              durch die Dauerausstellung. Dabei können Sie zusätzlich den
              Tastleitplan zur Hilfe nehmen. Auf dem Tastleitplan sind alle
              Audio-Angebote mit einer Nummer markiert. Auf den
              Media-Guide-Geräten des Museums können Sie die Nummern über die
              Tasten eingeben. Dann erhalten Sie den passenden Audio-Text dazu.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain>
          <Stack space={6}>
            <Heading as="h2" level={3}>
              Gedichtlaufstrecke im Garten
            </Heading>
            <AudioPlayer src="mediaguide/museumsgarten" />
            <Paragraph>
              Den Media-Guide können Sie auch im Museumsgarten nutzen. Im Garten
              gibt es eine Gedichtlaufstrecke. Auf dieser Laufstrecke können Sie
              drei Gedichte von Friedrich Hölderlin in drei verschiedenen
              Geschwindigkeiten anhören. Mit dem Media-Guide können Sie eines
              der drei Gedichte auswählen. Und Sie können auswählen, ob es
              mäßig, zügig oder schnell vorgelesen werden soll. Dann können Sie
              mit dem Gedicht auf dem Weg spazieren gehen. Wenn das Gedicht
              langsam vorgelesen wird, haben Sie mehr Zeit bis zum Ende der
              Strecke. Wenn das Gedicht schnell vorgelesen wird, müssen Sie sich
              beeilen, damit Sie am Ende der Strecke ankommen, wenn auch das
              Gedicht zu Ende ist.
            </Paragraph>
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default Mediaguide
